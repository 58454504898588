import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../layouts"
import Container from "../components/container"
import Header from "../components/header"
import Footer from "../components/footer"
import HeroContainer from "../components/containers/hero"
import Title from "../components/hero/title"
import Subtitle from "../components/hero/subtitle"
import LinkToParentPage from "../components/link-to-parent-page"

import { articleStyles, smallCapsStyles } from "../utils/styles"
import {
  mediaQueries,
  transition,
  colors,
  fontSizes,
  space,
} from "../utils/presets"
import { rhythm } from "../utils/typography"

export default class DocsPage extends React.Component {
  render() {
    const { data, location } = this.props
    const { nav, markdownRemark: post } = data

    return (
      <Layout pathname={location.pathname}>
        <Helmet title={post.frontmatter.title} />
        <Header navItems={nav.edges} />
        <HeroContainer
          customStyles={{
            paddingBottom: space[9],
            [mediaQueries.desktop]: {
              paddingBottom: space[13],
            },
          }}
        >
          <Container>
            {location.pathname !== `/docs/` && (
              <LinkToParentPage link="/docs/" title="Gatsby Cloud Docs" />
            )}
            <Title customStyles={{ maxWidth: rhythm(24) }}>
              {post.frontmatter.title}
            </Title>
            {post.frontmatter.subtitle && (
              <Subtitle customStyles={{ maxWidth: rhythm(30) }}>
                {post.frontmatter.subtitle}
              </Subtitle>
            )}
          </Container>
        </HeroContainer>
        <Container customStyles={{ position: `static` }}>
          <div css={{ maxWidth: rhythm(46) }}>
            <div
              css={{
                borderBottom: `1px solid ${colors.gray.faint}`,
                padding: `${space[9]} 0`,
                marginBottom: space[12],
                [mediaQueries.desktop]: {
                  borderBottom: 0,
                  float: `right`,
                  marginLeft: space[9],
                  width: rhythm(10),
                },
              }}
            >
              {post.tableOfContents && (
                <>
                  <h2 css={smallCapsStyles}>Table of Contents</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: post.tableOfContents }}
                    css={{
                      ul: {
                        listStyle: `none`,
                        [mediaQueries.desktop]: {
                          fontSize: fontSizes[1],
                        },
                      },
                      "& > ul": {
                        margin: 0,
                      },
                      a: {
                        color: colors.gray.calm,
                        textDecoration: `none`,
                        transition: `color ${transition.speed.default} ${
                          transition.curve.default
                        }`,
                        ":hover": {
                          color: colors.gatsby,
                        },
                      },
                    }}
                  />
                </>
              )}
            </div>
            <div
              css={{
                ...articleStyles,
                [mediaQueries.desktop]: {
                  paddingRight: rhythm(12),
                },
              }}
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
        </Container>
        <Footer navItems={nav.edges} />
      </Layout>
    )
  }
}

DocsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export const pageQuery = graphql`
  query($id: String!) {
    ...MainNavigation
    markdownRemark(id: { eq: $id }) {
      html
      tableOfContents
      frontmatter {
        title
        subtitle
      }
    }
  }
`
